import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0.5rem'};
  border-bottom: ${props => props.borderBottom};
  margin-bottom: ${props => props.marginBottom};

  span {
    font-size: 1.2em;
    margin-bottom: 3px;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    justify-content: flex-start;
  }
`
